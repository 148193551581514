import { getToken } from "@jca/libs/api";
import { useQuery } from "@tanstack/vue-query";
import { type FeatureCollection } from "geojson";
import JSZip from "jszip";
import { Api } from "./models";

const defaultUrl = import.meta.env.VITE_AGCO_DATA_EXCHANGE_API_BASE_URL as
	| string
	| undefined;

const dataExchangeServiceHttpClient = new Api({
	baseApiParams: { secure: true },
	securityWorker: async () => {
		const headers: Record<string, string> = {
			Authorization: `Bearer ${await getToken()}`,
		};

		return { headers };
	},
});

export const initDataExchangeServiceClient = () => {
	dataExchangeServiceHttpClient.baseUrl = `${defaultUrl}`;
};

export const useDataExchangeServiceHttp = (): Api<unknown> =>
	dataExchangeServiceHttpClient as Api<unknown>;

export async function unzipSpatialFileStream(
	file: Blob,
): Promise<FeatureCollection | undefined> {
	try {
		const zip = new JSZip();
		const unzipped = await zip.loadAsync(file);
		const files = Object.values(unzipped.files);
		const fileData = await files[0].async("string");
		return JSON.parse(fileData) as FeatureCollection;
	} catch (err) {
		console.error("Error encountered while unzipping file.");
		console.error(err);
	}
}

export async function convertSpatialFileToGeoJson(
	http: Api<unknown>,
	file: File,
): Promise<FeatureCollection | undefined> {
	try {
		const data: {
			file: File;
			"export-format": string;
			"import-format": string;
		} = {
			file: file,
			"export-format": "GeoJson",
			"import-format": "Auto",
		};
		const res = await http.convertFile.convertFile(data);
		const blob = await new Response(res.body).blob();
		return await unzipSpatialFileStream(blob);
	} catch (err) {
		console.error("Error converting spatial file to GeoJSON:", err);
		throw new Error("Error converting spatial file to GeoJSON.");
	}
}

export function useSupportedImportFormats(http: Api<unknown>) {
	async function getSupportedImportFormats(): Promise<string[]> {
		const res =
			await http.supportedImportFormats.getSupportedImportFormats();
		const data = res.data as { formats: string[] };
		const supportedImportFormats = data.formats as string[];
		return supportedImportFormats;
	}

	return useQuery({
		queryKey: ["supportedImportFormats"],
		queryFn: getSupportedImportFormats,
		placeholderData: [],
	});
}
