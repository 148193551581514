/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiErrorMsg {
	/** @format int32 */
	status?: number;
	message?: string;
}

export interface ISO11783TaskData {
	children?: object[];
	versionMajor?: string;
	versionMinor?: string;
	managementSoftwareManufacturer?: string;
	managementSoftwareVersion?: string;
	taskControllerManufacturer?: string;
	taskControllerVersion?: string;
	dataTransferOrigin?: string;
	lang?: string;
}

export interface ExportFileRequestBody {
	content?: ISO11783TaskData;
	format?: string;
}

export interface GetSupportedImportFormatsResponse {
	formats?: string[];
}

export interface GetSupportedExportFormatsResponse {
	formats?: string[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "/v2/api";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key]);
		return keys
			.map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
		[ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === "object" && property !== null
							? JSON.stringify(property)
							: `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
			},
			signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
			body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
		}).then(async response => {
			const r = response.clone() as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then(data => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch(e => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title data-exchange-service APIs
 * @version v1
 * @baseUrl /v2/api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	readFile = {
		/**
		 * @description Read a file and get file data in json, xml or isoxml. Based on Accept header (application/json, application/isoxml ...). Takes an optional parameter "import-format", if used the dedicated importer is used, else the auto importer is used. File data can be used in export endpoint to export to a new file format
		 *
		 * @tags ReadFile
		 * @name ReadFile
		 * @request POST:/read-file
		 * @secure
		 */
		readFile: (
			data: {
				/** @format binary */
				file: File;
				"import-format"?: any;
			},
			params: RequestParams = {},
		) =>
			this.request<ISO11783TaskData, ApiErrorMsg>({
				path: `/read-file`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),
	};
	exportFile = {
		/**
		 * @description Takes the response from the /read-file endpoint and a supported-export-format. Exports it into the chosen supported-export-format and returns a bytearray  Also returns a Content-Disposition header describing the filename/filetype
		 *
		 * @tags ExportFile
		 * @name ExportFile
		 * @request POST:/export-file
		 * @secure
		 */
		exportFile: (data: ExportFileRequestBody, params: RequestParams = {}) =>
			this.request<string[], ApiErrorMsg>({
				path: `/export-file`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	convertFile = {
		/**
		 * @description Takes a file and a supported-export-format to convert to, a optional supported-import-format can also be sent to use the dedicated importer, if not sent it uses the auto importer. Converts the file to the chosen format and returns it as a bytearray Also returns a Content-Disposition header describing the filename/filetype
		 *
		 * @tags ConvertFile
		 * @name ConvertFile
		 * @request POST:/convert-file
		 * @secure
		 */
		convertFile: (
			data: {
				/**
				 * The zip file to be converted
				 * @format binary
				 */
				file: File;
				/** The format to convert the provided file to */
				"export-format"?: any;
				/** The format of the provided file. If know specify to reduce search time otherwise use the Auto option */
				"import-format"?: any;
				/** Exclude an importer from the import intended to be used for the Auto import-format. Separate with , ; or space i.e: A,B */
				"auto-import-exclude-formats"?: any;
				/** If specified only the importers where the pattern matches one of their id is considered. This is to control the Auto import that other wise considers all available importers. Separate with , ; or space i.e: A,B */
				"auto-import-include-formats"?: any;
			},
			params: RequestParams = {},
		) =>
			this.request<string[], ApiErrorMsg>({
				path: `/convert-file`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				...params,
			}),
	};
	version = {
		/**
		 * No description
		 *
		 * @tags Version
		 * @name Version
		 * @request GET:/version
		 */
		version: (params: RequestParams = {}) =>
			this.request<string, ApiErrorMsg>({
				path: `/version`,
				method: "GET",
				...params,
			}),
	};
	supportedImportFormats = {
		/**
		 * No description
		 *
		 * @tags SupportedFormats
		 * @name GetSupportedImportFormats
		 * @request GET:/supported-import-formats
		 * @secure
		 */
		getSupportedImportFormats: (params: RequestParams = {}) =>
			this.request<GetSupportedImportFormatsResponse, ApiErrorMsg>({
				path: `/supported-import-formats`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	supportedExportFormats = {
		/**
		 * No description
		 *
		 * @tags SupportedFormats
		 * @name GetSupportedExportFormats
		 * @request GET:/supported-export-formats
		 * @secure
		 */
		getSupportedExportFormats: (params: RequestParams = {}) =>
			this.request<GetSupportedExportFormatsResponse, ApiErrorMsg>({
				path: `/supported-export-formats`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	health = {
		/**
		 * No description
		 *
		 * @tags Health
		 * @name IsAlive
		 * @request GET:/health
		 */
		isAlive: (params: RequestParams = {}) =>
			this.request<string, ApiErrorMsg>({
				path: `/health`,
				method: "GET",
				...params,
			}),
	};
	fendtone = {
		/**
		 * No description
		 *
		 * @tags OpenApiSpec
		 * @name GetFendtOneV2Spec
		 * @request GET:/fendtone
		 */
		getFendtOneV2Spec: (params: RequestParams = {}) =>
			this.request<string[], ApiErrorMsg>({
				path: `/fendtone`,
				method: "GET",
				...params,
			}),
	};
	balelink = {
		/**
		 * No description
		 *
		 * @tags OpenApiSpec
		 * @name GetBalelinkV2Spec
		 * @request GET:/balelink
		 */
		getBalelinkV2Spec: (params: RequestParams = {}) =>
			this.request<string[], ApiErrorMsg>({
				path: `/balelink`,
				method: "GET",
				...params,
			}),
	};
}
