import { getApiUrl } from "@/aws-configuration";
import { getApiVersionSetting, useSettings } from "@/composables/settings";
import {
	getUserFromIdToken,
	initClient,
	isUserFlightPathAdmin,
	setCustomerId,
	setOrganizationId,
	useCustomerId,
} from "@jca/libs/api";
import { initFlightpathServiceClient } from "@jca/libs/flightpath-service-api";
import { callbackHandler, johnDeereAuthGuard } from "@jca/libs/johndeere";
import { initMachineCertificateClient } from "@jca/libs/machine-certificate-api";
import { initDataExchangeServiceClient } from "@shared/data-exchange-service";
import {
	createRouter,
	createWebHistory,
	type NavigationGuard,
} from "vue-router";

const matchUUID = (param: string) =>
	`:${param}([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$)`;

const isUserFlightPathAdminNavigationGuard: NavigationGuard = async (
	to,
	_from,
	next,
) => {
	try {
		const user = await getUserFromIdToken();
		if (!user.groups.includes("FlightPathAdmin")) {
			next("/");
			throw new Error("Not authorized");
		}
		next();
	} catch (error) {
		// prevent navigation to the next page user is not a flightpathadmin
		next("/");
	}
};

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/auth",
			name: "auth",
			component: () => import("../views/AuthView.vue"),
		},
		{
			path: "/",
			component: () => import("../views/AppLayout.vue"),
			children: [
				{
					path: "users",
					name: "users",
					component: () => import("../views/UsersView.vue"),
				},
				{
					path: "payments",
					name: "payments",
					component: () => import("../views/PaymentsView.vue"),
				},
				{
					path: `subscriptions/${matchUUID("machineId")}?`,
					name: "subscriptions",
					component: () => import("../views/EditPaymentsView.vue"),
				},
				{
					path: "settings",
					name: "settings",
					component: () => import("../views/SettingsView.vue"),
				},
				{
					path: "switch-organization",
					name: "switch-organization",
					component: () =>
						import("../views/SwitchOrganizationView.vue"),
					beforeEnter: [isUserFlightPathAdminNavigationGuard],
				},
				{
					path: `fields/${matchUUID("fieldId")}?`,
					name: "fields",
					component: () => import("../views/FieldsView.vue"),
				},
				{
					path: `implements/${matchUUID("implementId")}?`,
					name: "implements",
					component: () => import("../views/ImplementsView.vue"),
				},
				{
					path: "users",
					name: "users",
					component: () => import("../views/UsersView.vue"),
				},

				{
					path: "settings",
					name: "settings",
					component: () => import("../views/SettingsView.vue"),
				},
				{
					path: "",
					redirect: { name: "users" },
				},
			],
		},
		{
			path: "/import-johndeere",
			name: "import-johndeere",
			component: () => import("@/views/ImportJohnDeereView.vue"),
			beforeEnter: async (to, from, next) => {
				const guard = johnDeereAuthGuard(
					getApiUrl(getApiVersionSetting()),
				);
				const result = await guard(to, from, next);
				if (result) {
					next();
				}
			},
		},
		{
			path: "/import-field",
			name: "import-field",
			component: () => import("@/views/ImportFieldView.vue"),
			beforeEnter: () => {
				initDataExchangeServiceClient();
			},
		},
		{
			path: "/callback",
			name: "callback",
			component: () => {},
			beforeEnter: async (to, from, next) => {
				await callbackHandler(
					to,
					from,
					next,
					getApiUrl(getApiVersionSetting()),
					"/import-johndeere",
				);
			},
		},
		{
			path: "/:pathMatch(.*)*",
			redirect: { name: "users" },
		},
	],
});

router.beforeEach(async to => {
	function redirect() {
		if (to.name !== "auth" && to.name !== "forgot-password") {
			return {
				name: "auth",
				query: { redirect: to.name?.toString() },
			};
		}
	}

	try {
		const { apiVersion, organizationId } = useSettings();
		initClient(false, "", "", getApiUrl(apiVersion.value));
		initFlightpathServiceClient(getApiUrl(apiVersion.value));
		initMachineCertificateClient(getApiUrl(apiVersion.value));

		if (await isUserFlightPathAdmin()) {
			if (
				!(await setOrganizationId(
					organizationId.value ?? useCustomerId(),
				))
			)
				return redirect();
		} else {
			if (!(await setCustomerId(false))) return redirect();
		}

		return;
	} catch (e: unknown) {
		redirect();
	}
});

export default router;
